import React from "react";
import { Link } from "react-router-dom";
import men from "../assets/men.png";
const Join = () => {
  return (
    <section className="join">
      <div className="join__wrapper">
        <div className="join__content">
          <div className="join__content-img">
            <img src={men} alt="men" />
          </div>
          <div className="join__content-text">
            <h2>
              JOIN US NOW <br />
              IT’S FREE!
            </h2>
            <a
              href="https://client.cardani.io/register"
              className="openAccount"
            >
              Open Account
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Join;
